





















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';

@Component
export default class MainInfo extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public product!: any;

  @Prop({
    required: true
  })
  public place!: any;

  @Prop()
  public placeMenu!: any;
  @Prop()
  public selectLang!: any;

  @Watch('selectLang')
  public selectLangUpdate() {
    this.$emit('refresh');
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
  }
}
